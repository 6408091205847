




































import Vue from "vue";
import { SocialLinkInit } from "@/interfaces/onboarding/onboarding";
import {
  clean_string,
  get_facebook_regex,
  get_instagram_regex,
  get_linkedin_regex,
  get_twitter_regex
} from "@/utils/global";
import { SocialLinks } from "@/store/modules/onboarding/interfaces";
import { mapGetters } from "vuex";
import { GET_ONBOARDING_LOADING } from "@/store/modules/onboarding/constants";

export default Vue.extend({
  name: "CandidateSocialLinks",
  data() {
    return {
      social_channels: [] as SocialLinkInit[],
      form: true as boolean
    };
  },
  computed: {
    ...mapGetters("onboarding", {
      get_onboarding_loading: GET_ONBOARDING_LOADING
    })
  },
  mounted() {
    this.social_channels = [
      {
        name: "Facebook",
        v_model: null,
        prepend_icon: require("@/assets/logos/facebook.svg"),
        field_rules: [
          (value: string) => {
            if (value) {
              if (get_facebook_regex().test(value)) return true;
              else return this.$t("candidate-onboarding.invalid-fb-url");
            } else return true;
          }
        ]
      },
      {
        name: "Twitter",
        v_model: null,
        prepend_icon: require("@/assets/logos/twitter.svg"),
        field_rules: [
          (value: string) => {
            if (value) {
              if (get_twitter_regex().test(value)) return true;
              else return this.$t("candidate-onboarding.invalid-twitter-url");
            } else return true;
          }
        ]
      },
      {
        name: "Linkedin",
        v_model: null,
        prepend_icon: require("@/assets/logos/linkedin-3.svg"),
        field_rules: [
          (value: string) => {
            if (value) {
              if (get_linkedin_regex().test(value)) return true;
              else return this.$t("candidate-onboarding.invalid-linkedin-url");
            } else return true;
          }
        ]
      },
      {
        name: "Instagram",
        v_model: null,
        prepend_icon: require("@/assets/logos/instagram.svg"),
        field_rules: [
          (value: string) => {
            if (value) {
              if (get_instagram_regex().test(value)) return true;
              else return this.$t("candidate-onboarding.invalid-insta-url");
            } else return true;
          }
        ]
      }
    ];
  },
  methods: {
    submit_form() {
      const social_links: SocialLinks[] = [];
      this.social_channels.forEach((value: SocialLinkInit) => {
        const obj: SocialLinks = {
          platform: clean_string(value.name),
          url: value.v_model ?? ""
        };
        social_links.push(obj);
      });
      this.$emit("update_stepper", {
        step: 4,
        value: social_links
      });
    }
  }
});
